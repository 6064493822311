<h2 mat-dialog-title>Agrega una categoria</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field>
        <mat-label>Nombre de la nueva categoria</mat-label>
        <input matInput [(ngModel)]="category.name">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button color="primary" [mat-dialog-close]="category" cdkFocusInitial>Agregar</button>

</mat-dialog-actions>