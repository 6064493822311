<div class="header">
    <button mat-dialog-close mat-icon-button color="warn">
        <mat-icon>clear</mat-icon>
    </button>
    <h6 mat-dialog-title>{{ titulo }}</h6>
</div>

<mat-dialog-content>
    <p [innerHTML]="mensajeConfirmacion"></p>
</mat-dialog-content>

<div class="botonCentro">
    <mat-dialog-actions>
        <button mat-stroked-button color="warn" (click)="dialogRef.close(false)">No, volver</button>
        <button mat-raised-button (click)="dialogRef.close(true)" color="warn">S&iacute;,
            estoy seguro</button>
    </mat-dialog-actions>
</div>