import { Component, OnInit, Input } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';

import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { tap, finalize } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Product } from '../../../shared/model/product.model';
import { Category } from '../../../shared/model/category.model';


@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  uploadForm: FormGroup;
  prefix: string;
  @Input() file: File;
  @Input() categories: Observable<Category[]>;

  @Input() categoryTypeName: string;
  @Input() productCollectionName: string;

  imgURL: any;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  product: Product;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore
  ) {
    this.prefix = '';
    this.product = new Product();
  }

  ngOnInit() {
    console.log(this.file);
    var reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.createForm();
  }

  createForm() {
    this.uploadForm = new FormGroup({
      category: new FormControl('', [Validators.required]),
      cod: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      desc: new FormControl('', [Validators.required]),
      outStock: new FormControl(''),
      hotSale: new FormControl(''),
      soon: new FormControl(''),
      type: new FormControl(''),
      videoLink: new FormControl(''),
    });
  }

  saveButton() {
    this.product.cod = this.prefix + this.uploadForm.get('cod').value;
    this.product.name = this.uploadForm.get('name').value;
    this.product.category = this.uploadForm.get('category').value;
    this.product.price = this.uploadForm.get('price').value;
    this.product.desc = this.uploadForm.get('desc').value;
    this.product.outStock = this.uploadForm.get('outStock').value;
    this.product.hotSale = this.uploadForm.get('hotSale').value;
    this.product.soon = this.uploadForm.get('soon').value;
    this.product.videoLink = this.uploadForm.get('videoLink').value;
    this.product.type = this.categoryTypeName;
    this.product.creationDate = Date.now();

    this.startUpload();
  }

  startUpload() {
    // The storage path
    const path = `${this.product.category}/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        console.log('*******');
        // this.downloadURL = await ref.getDownloadURL().toPromise();
        this.product.downloadURL = await ref.getDownloadURL().toPromise();
        this.product.path = path;

        this.db.collection(this.productCollectionName).add({ ...this.product }).then(docRef => {
          console.log("Document written with ID: ", docRef.id);
          console.log("You can now also access .this as expected: ", this.product)
        })
          .catch(error => console.error("Error adding document: ", error));
      })
    );
    console.log(this.snapshot);
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  changeTypeProduct(type) {
    this.prefix = type.value.toUpperCase().charAt(0) + "-";
    console.log(this.prefix);
  }
}

