import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Product } from '../../shared/model/product.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProductService } from '../../shared/services/product.service';
import { CategoryService } from '../../shared/services/category.service';
import { DialogEditProductComponent } from './dialog-edit-product/dialog-edit-product.component';
import { Observable } from 'rxjs';
import { Category } from '../../shared/model/category.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogConfirmDeleteComponent } from 'src/app/shared/dialog-confirm-delete/dialog-confirm-delete.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, AfterViewInit {

  @Input() categoryTypeName: string;
  @Input() categoryCollectionName: string;
  @Input() productCollectionName: string;

  // Dialogo borrado
  dialogoBorrar: MatDialogRef<DialogConfirmDeleteComponent>;

  // Mensajes Dialogo
  @ViewChild('mensajeEliminandoProducto') mensajeEliminandoPerfil: ElementRef;
  @ViewChild('mensajeConfirmarEliminacionProducto') mensajeConfirmarEliminacionPerfil: ElementRef;

  isHovering: boolean;

  isNewProduct: boolean

  files: File[] = [];

  categories: Observable<Category[]>;

  displayedColumns = ['downloadURL', 'cod', 'name', 'desc', 'outStock', 'category', 'creationDate', 'price',
    'hotSale', 'soon', 'actions'];

  dataSource: MatTableDataSource<Product>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private productService: ProductService, private categoryService: CategoryService,
    private authService: AuthService,
    public dialog: MatDialog) {
    this.isNewProduct = false;
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    // this.db.collection<Product>('products').valueChanges().subscribe(products => {
    //   this.dataSource.data = products;
    // });
    this.productService.getAllProductsAndMetadata(this.productCollectionName).subscribe(products => {
      console.log(products)
      this.dataSource.data = products;
    });
    this.categories = this.categoryService.getAllCategories(this.categoryCollectionName);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

  createProductButton() {
    this.isNewProduct = true;
  }

  cancelProductButton() {
    this.isNewProduct = false;
    this.files = [];
  }

  openEditProductDialog(product: Product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = 500;
    dialogConfig.data = {
      product: product,
      categories: this.categories,
      productCollectionName: this.productCollectionName,
      categoryTypeName: this.categoryTypeName
    };

    this.dialog.open(DialogEditProductComponent, dialogConfig);
  }

  deleteProduct(product) {
    console.log(product);
    // this.productService.deleteProductById(product);

    this.dialogoBorrar = this.dialog.open(DialogConfirmDeleteComponent, { disableClose: false });
    this.dialogoBorrar.componentInstance.titulo = (this.mensajeEliminandoPerfil.nativeElement.innerHTML.split('_')[0]
      // + perfil.nombre_perfil.toUpperCase()
      + this.mensajeEliminandoPerfil.nativeElement.innerHTML.split('_')[1]);
    this.dialogoBorrar.componentInstance.mensajeConfirmacion = this.mensajeConfirmarEliminacionPerfil.nativeElement.innerHTML;
    this.dialogoBorrar.afterClosed().subscribe(result => {
      if (result) {
        this.productService.deleteProductById(this.productCollectionName, product);
      }
      this.dialogoBorrar = null;
    });

  }
  logout() {
    this.authService.signOut();
  }
}
