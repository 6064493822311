<!-- <div class="page-header page-header header-small"
    style="background-image: url('/../assets/images/scrapbook-1.jpg'); transform: translate3d(0px, 1.333333px, 0px);">
    <div class="container">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
                <div class="brand">
                    <h1 class="title">Mira Que Lindo</h1>
                    <h4>Free global delivery for all products. Use coupon <b>25summer</b> for an extra 25% Off</h4>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- slider Area Start-->
<!-- <div class="slider-area "> -->
<!-- Mobile Menu -->
<div class="container container-image" id="top"
    style="background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/../assets/images/scrapbook-1.jpg')">
    <div class="row">
        <div class="text-container-image col-md-10 ml-auto mr-auto text-center" id="main">
            <h1 class="mat-display-1">Mira Que Lindo</h1>
            <h3 class="subtitle">Bienvenid@s a tu tienda de <b>Scrapbooking</b> y <b>manualidades</b>. Traemos los
                mejores productos, con una diversidad de diseños en troqueles, sellos, embosing, papeles para
                encuadernar
                y muchos artículos más. Elige, diseña y disfruta el trabajar con nosotros.</h3>
        </div>
    </div>
</div>
<!-- </div> -->
<!-- slider Area End-->


<div class="main main-raised ">
    <div class="category-section">

      <div class="section">
        <div class="col-xl-4 col-lg-5 col-md-5">
            <div class="section-tittle">
                <h2>Categorías Principales</h2>
            </div>
        </div>
        <div class="row category-container">
            <div class="col-md-6">
                <mat-card class="category-card" (click)="navigateCategory('scrapbook')">
                    <div class="container-category-image"
                        style="background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/../assets/images/scrapbook-background-image.jpg')">
                        <div class="text-container-image">
                            <h2>Scrapbook</h2>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div class="col-md-6">
              <mat-card class="category-card" (click)="navigateCategory('carteras')">
                  <div class="container-category-image"
                      style="background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/../assets/images/purses-background-image.jpg')">
                      <div class=" text-container-image">
                          <h2>Bolsos / Carteras</h2>
                      </div>
                  </div>
              </mat-card>
          </div>

            <div class="col-md-6">
              <mat-card class="category-card" (click)="navigateCategory('juguetes')">
                  <div class="container-category-image"
                      style="background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/../assets/images/toys-background-image.jpg')">
                      <div class=" text-container-image">
                          <h2>Otros Juguetes</h2>
                      </div>
                  </div>
              </mat-card>
          </div>

        </div>
    </div>

        <div class="section">
            <div class="col-xl-4 col-lg-5 col-md-5">
                <div class="section-tittle mb-30">
                    <h2>Nuevos productos</h2>
                </div>
            </div>
            <div class="latest-product-area">
                <div class="row category-container">

                    <ng-container *ngIf="productsObs | async as prods">
                        <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let product of prods">
                            <mat-card class="product-card">
                                <!-- <mat-card-header> -->
                                <div class="card-image">
                                    <span *ngIf="product.outStock" class="out-of-stock">
                                        <span class="out-of-stock-tittle">Agotado</span>
                                    </span>

                                    <div *ngIf="product.hotSale" class="ribbon ribbon-top-right">
                                        <span>Promocion</span>
                                    </div>

                                    <div *ngIf="product.soon" class="soon">
                                        <span>Proximamente</span>
                                    </div>

                                    <!-- <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/t-shirt.png" alt="" /> -->
                                    <img src="{{product.downloadURL}}" alt="{{ product.name }}" />
                                </div>

                                <mat-card-content class="product-card-content">
                                    <div class="stats">
                                        <div class="stats-container">
                                            <div class="product-title">
                                                <span class="product_name">{{ product.name }}</span>
                                                <span class="product_price">S/{{ product.price }}</span>
                                            </div>
                                            <div class="product-desc">
                                                <p>{{ product.cod }} - {{ product.desc }} </p>
                                            </div>
                                        </div>
                                    </div>

                                </mat-card-content>

                            </mat-card>
                        </div>
                    </ng-container>


                    <!-- <ng-container *ngIf="productsObs | async as prods">
                    <div class="col-md-4">
                        <mat-card class="product-card" *ngFor="let product of prods">
                            <div class="card-image">
                                <span *ngIf="product.outStock" class="out-of-stock">
                                    <span class="out-of-stock-tittle">Agotado</span>
                                </span>
                                <img src="{{product.downloadURL}}" alt="{{ product.name }}" />
                            </div>

                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">{{ product.name }}</span>
                                            <span class="product_price">S/.{{ product.price }}</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>{{ product.cod }} - {{ product.desc }} </p>
                                        </div>
                                    </div>
                                </div>

                            </mat-card-content>

                        </mat-card>
                    </div>
                </ng-container> -->



                    <!-- <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">
                                <img
                                    src="https://images.unsplash.com/photo-1533676525479-2afa4c395bb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60" />
                            </div>
                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>

                    <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">
                                <img
                                    src="https://images.unsplash.com/photo-1596998791568-386ef5297c2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60" />
                            </div>

                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>

                    <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">
                                <img
                                    src="https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />

                            </div>

                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>



                    <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">

                                <img
                                    src="https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
                            </div>

                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>

                    <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">
                                <img
                                    src="https://images.unsplash.com/photo-1451187863213-d1bcbaae3fa3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
                            </div>


                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>

                    <div class="col-md-4">
                        <mat-card class="product-card">
                            <div class="card-image">

                                <img
                                    src="https://images.unsplash.com/photo-1504610926078-a1611febcad3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
                            </div>

                            <mat-card-content class="product-card-content">
                                <div class="stats">
                                    <div class="stats-container">
                                        <div class="product-title">
                                            <span class="product_name">Prodcuto 1</span>
                                            <span class="product_price">S/.39</span>
                                        </div>
                                        <div class="product-desc">
                                            <p>cod - Descripcion 1 </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div> -->


                    <div class="row">
                        <div class="col-md-12 mr-auto">
                            <a routerLink="/categorias">
                                <button id="allProducts" mat-raised-button color="accent">Ver todos
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                            </a>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

<div class="container">


    <div class="section category-section">
        <!-- <div class="col-xl-4 col-lg-5 col-md-5">
            <div class="section-tittle">
                <h2>Categorias Principales</h2>
            </div>
        </div> -->
        <div class="row d-flex justify-content-between">
            <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="single-method mb-40">
                    <img class="icon" src="../assets/icons/camion-de-reparto.svg" alt="delivery">
                    <h5>Entregas</h5>
                    <ul>
                        <li>Recojo en tienda (previa coordinación).</li>
                        <li>Envío por delivery Lima: Sr. Moto, Glovo, Rappi (previa coordinación)</li>
                        <li>Envío por delivery a todo el país.(Solicite cotizaciones)</li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="single-method mb-40">
                    <img class="icon" src="../assets/icons/servicio-al-cliente.svg" alt="servicio al cliente">
                    <h5>Atención al Cliente</h5>
                    <ul>
                        <li>Atención de pedidos, cotizaciones y ventas </li>
                        <li>Horario: de 9:00 am a 7:00 pm</li>
                        <li>Whatsapp +51 951934800</li>
                    </ul>

                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="single-method mb-40">
                    <img class="icon" src="../assets/icons/tarjeta-de-credito.svg" alt="medio de pago">
                    <h5>Medios de pago</h5>
                    <ul>
                        <li>Efectivo</li>
                        <li>Aceptamos tarjetas de crédito y/o débito de todos los bancos</li>
                        <li>Transferencia bancaria</li>
                        <li>Pago Link</li>
                        <li>Yape, Lukita</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Latest Products Start -->

<!-- Latest Products End -->

<!-- Shop Method Start-->
<!-- <div class="shop-method-area section-padding30">
    <div class="shop-method-container">

        <div class="row d-flex justify-content-between">

            <div class="category-container">
                <mat-card>
                    <div class="category-caption">
                        <i class="ti-package"></i>
                        <h6>Free Shipping Method</h6>
                        <p>aorem ixpsacdolor sit ameasecur adipisicing elitsf edasd.</p>
                    </div>
                </mat-card>
                <mat-card>
                    <div class="single-method mb-40">
                        <i class="ti-unlock"></i>
                        <h6>Secure Payment System</h6>
                        <p>aorem ixpsacdolor sit ameasecur adipisicing elitsf edasd.</p>
                    </div>
                </mat-card>
                <mat-card>
                    <div class="single-method mb-40">
                        <i class="ti-reload"></i>
                        <h6>Secure Payment System</h6>
                        <p>aorem ixpsacdolor sit ameasecur adipisicing elitsf edasd.</p>
                    </div>
                </mat-card>
            </div>

        </div>
    </div>
</div> -->
<!-- Shop Method End-->
