<div class="parent">
  <header>
    <app-navbar></app-navbar>
  </header>
  <main>
    <!-- <div [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"> </router-outlet>
    </div> -->
    <div>
      <router-outlet> </router-outlet>
    </div>
  </main>

  <!-- <footer id="sticky-footer" class="py-4 bg-dark text-white-50"> -->
  <app-footer></app-footer>

  <!-- </footer> -->
</div>