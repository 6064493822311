import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/shared/model/category.model';

@Component({
  selector: 'app-dialog-add-category',
  templateUrl: './dialog-add-category.component.html',
  styleUrls: ['./dialog-add-category.component.scss']
})
export class DialogAddCategoryComponent implements OnInit {

  category: Category;

  constructor(
    public dialogRef: MatDialogRef<DialogAddCategoryComponent>) {
    this.category = new Category();
  }

  ngOnInit(): void {
  }

}
