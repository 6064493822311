import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/shared/model/category.model';
import { CategoryService } from 'src/app/shared/services/category.service';
import { DialogAddCategoryComponent } from './dialog-add-category/dialog-add-category.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmDeleteComponent } from 'src/app/shared/dialog-confirm-delete/dialog-confirm-delete.component';

import { CategoryCollectionNames } from "../../../shared/enums";

@Component({
  selector: 'app-categories-upload',
  templateUrl: './categories-upload.component.html',
  styleUrls: ['./categories-upload.component.scss']
})
export class CategoriesUploadComponent implements OnInit {

  @Input() categoryTypeName: string;
  @Input() categoryCollectionName: string;

  // Dialogo borrado
  dialogoBorrar: MatDialogRef<DialogConfirmDeleteComponent>;

  // Mensajes Dialogo
  @ViewChild('mensajeEliminandoCategoria') mensajeEliminandoPerfil: ElementRef;
  @ViewChild('mensajeConfirmarEliminacionCategoria') mensajeConfirmarEliminacionPerfil: ElementRef;

  categories: Observable<Category[]>;

  constructor(private categoryService: CategoryService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.categories = this.categoryService.getAllCategories(this.categoryCollectionName);
    console.log(this.categoryCollectionName)
  }

  openAddCategoryDialog(): void {
    const dialogRef = this.dialog.open(DialogAddCategoryComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.categoryService.addCategory(this.categoryCollectionName,result);
      }
    });
  }

  deleteCategoryButton(category) {
    console.log(category);
    // this.categoryService.deleteCategory(category);


    this.dialogoBorrar = this.dialog.open(DialogConfirmDeleteComponent, { disableClose: false });
    this.dialogoBorrar.componentInstance.titulo = (this.mensajeEliminandoPerfil.nativeElement.innerHTML.split('_')[0]
      // + perfil.nombre_perfil.toUpperCase()
      + this.mensajeEliminandoPerfil.nativeElement.innerHTML.split('_')[1]);
    this.dialogoBorrar.componentInstance.mensajeConfirmacion = this.mensajeConfirmarEliminacionPerfil.nativeElement.innerHTML;
    this.dialogoBorrar.afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.deleteCategory(this.categoryCollectionName, category);
      }
      this.dialogoBorrar = null;
    });

  }

}

