<!-- Dialogos -->
<span [hidden]=true #mensajeEliminandoProducto> Eliminando producto _
</span>
<span [hidden]=true #mensajeConfirmarEliminacionProducto>
  ¿Est&aacute; seguro de eliminar el producto? </span>


  <!-- <button mat-raised-button color="primary" (click)="logout()">logout
    productos</button> -->

  <div class="container-categories-products">
    <div class="row">
      <div class="col-lg-3">

        <div class="container-categories">
          <app-categories-upload [categoryTypeName]="categoryTypeName" [categoryCollectionName]="categoryCollectionName"></app-categories-upload>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="row">
          <!-- https://fireship.io/lessons/material-data-tables-with-firestore/ -->
          <div [hidden]="isNewProduct" class="container-products">
            <mat-card>
              <h2>Lista de Productos {{categoryTypeName}}</h2>
              <button mat-raised-button color="primary" (click)="createProductButton()"
                [disabled]="isNewProduct">Agregar
                productos</button>

              <mat-form-field style="margin-top: 16px;">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
              </mat-form-field>

              <div>
                <table mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="downloadURL">
                    <th mat-header-cell *matHeaderCellDef> Imagen </th>
                    <td mat-cell *matCellDef="let row"> <img class="img-table" [src]="row.downloadURL" /> </td>
                  </ng-container>

                  <ng-container matColumnDef="cod">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
                    <td mat-cell *matCellDef="let row"> {{row.cod}} </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion </th>
                    <td mat-cell *matCellDef="let row"> {{row.desc}} </td>
                  </ng-container>

                  <ng-container matColumnDef="outStock">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Agotado </th>
                    <td mat-cell *matCellDef="let row"> {{row.outStock}} </td>
                  </ng-container>

                  <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Categoria </th>
                    <td mat-cell *matCellDef="let row"> {{row.category}} </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                    <td mat-cell *matCellDef="let row"> S/.{{row.price}} </td>
                  </ng-container>

                  <ng-container matColumnDef="hotSale">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Promo </th>
                    <td mat-cell *matCellDef="let row"> {{row.hotSale}} </td>
                  </ng-container>

                  <ng-container matColumnDef="soon">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prox</th>
                    <td mat-cell *matCellDef="let row"> {{row.soon}} </td>
                  </ng-container>

                  <ng-container matColumnDef="creationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Creacion </th>
                    <td mat-cell *matCellDef="let row"> {{row.creationDate | date :'medium' }} </td>
                  </ng-container>

                  <ng-container matColumnDef="videoLink">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Link </th>
                    <td mat-cell *matCellDef="let row"> {{row.videoLink }} </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let row">
                      <button mat-icon-button aria-label="Editar producto" (click)="openEditProductDialog(row)">
                        <mat-icon>create</mat-icon>
                      </button>
                      <button mat-icon-button color="warn" aria-label="Eliminar producto" (click)="deleteProduct(row)">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No existe resultado para el filtro "{{input.value}}"</td>
                  </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
              </div>
            </mat-card>


          </div>

          <div [hidden]="!isNewProduct" class="container-products">
            <mat-card>
              <div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
                [class.hovering]="isHovering">
                <h3>Imagenes</h3>
                <p>Suelta las imagenes a cargar</p>
                <div class="file">
                  <label class="file-label">
                    <input class="file-input" type="file" (change)="onDrop($event.target.files)" multiple />

                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fa fa-upload"></i>
                      </span>
                      <span class="file-label">
                        o elige una imagen…
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <div *ngIf="files.length >0">
                <h2>Lista de productos a agregar</h2>
              </div>

              <div *ngFor="let file of files">
                <app-upload-task [file]="file" [categories]="categories" [categoryTypeName]="categoryTypeName"
                  [productCollectionName]="productCollectionName"></app-upload-task>
              </div>

              <div class="centeredButtons">
                <button mat-raised-button color="warn" (click)="cancelProductButton()">Volver</button>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
