<div class="container-fluid">

  <h1 class="section-title">Panel de Administracion</h1>

  <mat-tab-group>
    <mat-tab label="Scrapbook">1
      <ng-template matTabContent>
        <app-upload [categoryTypeName]="'Scrapbook'" [categoryCollectionName]="'categories'"
          [productCollectionName]="'products'"></app-upload>
      </ng-template>
    </mat-tab>
    <mat-tab label="Carteras">2
      <ng-template matTabContent>

        <app-upload [categoryTypeName]="'Carteras'" [categoryCollectionName]="'categoriesPurses'"
        [productCollectionName]="'productsPurses'"></app-upload>
      </ng-template>

    </mat-tab>
    <mat-tab label="Juguetes"> 3
      <ng-template matTabContent>

        <app-upload [categoryTypeName]="'Juguetes'" [categoryCollectionName]="'categoriesToys'"
        [productCollectionName]="'productsToys'"></app-upload>
      </ng-template>

    </mat-tab>
  </mat-tab-group>


</div>
