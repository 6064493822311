import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';
import { Category } from '../model/category.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categoryCollection: AngularFirestoreCollection<Category>;

  constructor(private afs: AngularFirestore) {
  }

  public getAllCategories(categoryCollectionName: string): Observable<Category[]> {

    this.categoryCollection = this.afs.collection<Category>(categoryCollectionName, ref => ref.orderBy('name', 'asc'));

    return this.categoryCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Category;
        console.log(data)
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  public addCategory(categoryCollectionName: string, category: Category) {
    this.categoryCollection = this.afs.collection<Category>(categoryCollectionName, ref => ref.orderBy('name', 'asc'));
    this.categoryCollection.add({ ...category });
  }

  public deleteCategory(categoryCollectionName: string, category: Category) {
    this.categoryCollection = this.afs.collection<Category>(categoryCollectionName, ref => ref.orderBy('name', 'asc'));
    this.categoryCollection.doc(category.id).delete();
  }

}
