import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { CategoryService } from 'src/app/shared/services/category.service';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { Category } from 'src/app/shared/model/category.model';
import { Product } from 'src/app/shared/model/product.model';
import { ProductService } from 'src/app/shared/services/product.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  throttleTime,
  mergeMap,
  scan,
  tap,
  map,
  switchMap,
  filter,
} from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionList } from '@angular/material/list';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('categorias') categorySelected: MatSelectionList;
  @ViewChild('categoriasCarteras') categoryPursesSelected: MatSelectionList;
  @ViewChild('categoriasJuguetes') categoryToysSelected: MatSelectionList;

  pageSize: number = 15;
  lastProduct: Product;
  firstProduct: Product;

  panelOpenState: boolean = true;
  panelOpenStatePurses: boolean = false;
  panelOpenStateToys: boolean = false;

  activeProductTypeCollection: string;

  mobileQuery: MediaQueryList;

  categories: Observable<Category[]>;
  categoriesPurses: Observable<Category[]>; //TODO
  categoriesToys: Observable<Category[]>; //TODO

  productsObs: Observable<Product[]>;
  productsObsAux: Observable<Product[]>;

  products: Product[];
  filteredProducts: Product[];

  categoryFilter$: BehaviorSubject<string | null>;
  filterText$: BehaviorSubject<string | null>;

  isAllProducts: boolean = true;
  isFinalPage: boolean = false;

  parameter: string = 'all';

  //Disable next and prev buttons
  disable_next: boolean;
  disable_prev: boolean;
  auxCount: number = 0;

  fragment: string;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private categoryService: CategoryService,
    private productService: ProductService,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 812px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.categoryFilter$ = new BehaviorSubject(null);
    this.filterText$ = new BehaviorSubject(null);
  }

  openState(category: string){
    if(category == 'scrapbook'){
      this.panelOpenState = true;
      this.panelOpenStatePurses = false;
      this.panelOpenStateToys = false;
      if(!this.categories) this.categories = this.categoryService.getAllCategories('categories');
      this.activeProductTypeCollection = 'products';

    }else if(category == 'carteras'){
      this.panelOpenState = false;
      this.panelOpenStatePurses = true;
      this.panelOpenStateToys = false;
      if(!this.categoriesPurses) this.categoriesPurses = this.categoryService.getAllCategories('categoriesPurses');
      this.activeProductTypeCollection = 'productsPurses';

    }else if(category == 'juguetes'){
      this.panelOpenState = false;
      this.panelOpenStatePurses = false;
      this.panelOpenStateToys = true;

      if(!this.categoriesToys) this.categoriesToys = this.categoryService.getAllCategories('categoriesToys');
      this.activeProductTypeCollection = 'productsToys';
    }

  }

  ngOnInit(): void {

    this.activatedRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      // console.log(params['category']);
      if (params['category']) {
        // this.parameter = params['category'];
        this.openState(params['category'])
      } else {
        this.categories = this.categoryService.getAllCategories('categories');
        this.activeProductTypeCollection = 'products';

        // this.initAllProducts();
      }
      this.filterByCategory('all');
    });

    this.reload();

    // this.productsObs.subscribe(last => {
    //   this.lastProduct = last[last.length - 1];
    //   this.firstProduct = last[0];

    //   console.log("entro a firstProduct " + this.firstProduct.name);
    //   console.log("entro a lastProduct " + this.lastProduct.name);

    // });

    // this.productsObs.subscribe(
    //   res => {
    //     this.products = res;
    //     this.filteredProducts = res;
    //     console.log(this.products);
    //   }
    // );
    // this.products.subscribe(res => console.log(res));
  }

  ngAfterViewInit() {
    // console.log(this.categorySelected);
    // console.log(this.categorySelected.options);
    // console.log(this.categorySelected.options.first);
    // console.log(this.categorySelected.options.first.value);
    // console.log(this.categorySelected.options.find(cat => cat.value == 'all'));
    this.categories.subscribe(
      () => {
        this.categorySelected.selectedOptions.select(
          this.categorySelected.options.find(
            (cat) => cat.value == this.parameter
          )
        );
      },
      // The 2nd callback handles errors.
      (err) => console.error(err),
      // The 3rd callback handles the "complete" event.
      () => {
        // console.log("observable complete");
      }
    );

    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {
      console.error(e);
    }

    // this.categorySelected.options.find(cat => cat.value == 'all')
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  reload() {
    this.disable_next = false;
    this.disable_prev = true;
    this.auxCount = 0;
    this.isFinalPage = false;
  }

  initAllProducts() {
    // console.log('initAllProducts function');
    this.productsObs = this.productService.getPaginatedProducts(this.activeProductTypeCollection,this.pageSize);
    this.productsObs.subscribe((data) => {
      if (data.length < this.pageSize) this.disable_next = true;
      if (data.length == 0) this.isFinalPage = true;
    });
    this.productsObsAux = this.productsObs;
  }

  filterProductsByCategory() {
    // console.log("filterProductsByCategory");

    this.productsObs = combineLatest([this.categoryFilter$]).pipe(
      switchMap(([category]) =>
        this.afs
          .collection<Product>(this.activeProductTypeCollection, (ref) => {
            // console.log(category);
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            // if (!category) {
            //   query = query.orderBy('cod', 'asc').limit(this.pageSize);
            //   console.log("entra no=" + category);
            // };

            if (category) {
              //click categoria
              query = query
                .where('category', '==', category)
                .orderBy('cod', 'asc');
              // console.log("categoria =" + category);
            }

            return query;
          })
          .valueChanges()
      )
    );
  }

  filterProductsByText() {
    this.productsObs = combineLatest([this.filterText$]).pipe(
      switchMap(([text]) =>
        this.afs
          .collection<Product>(this.activeProductTypeCollection, (ref) => {
            // console.log(category);
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            // if (!category) {
            //   query = query.orderBy('cod', 'asc').limit(this.pageSize);
            //   console.log("entra no=" + category);
            // };

            if (text) {
              //filtro por codigo
              query = query.where('cod', '==', text).orderBy('name', 'asc');
              // console.log("text =" + text);
            }
            return query;
          })
          .valueChanges()
      )
    );
  }

  // es posible juntar los 2 filterProductsByText y filterProductsByCategory (para filtrar por 2 campos a la vez quitando el orderby)

  // click en una categoria
  filterByCategory(category: string | null) {
    if (this.mobileQuery.matches) {
      this.panelOpenState = false;
      this.panelOpenStatePurses = false;
      this.panelOpenStateToys = false;
    }
    // console.log(category.name);
    this.isAllProducts = false;
    this.isFinalPage = false;

    // console.log(this.categorySelected);

    if (category == 'all') {
      // if (!this.productsObsAux)
      this.initAllProducts();
      this.showAllProducts();
    } else {
      this.filterProductsByCategory();
      this.categoryFilter$.next(category);
    }
  }

  // search by text
  applyFilterText(event: Event, category: string) {
    console.log(category);
    console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    // console.log(filterValue);
    if (filterValue) {
      this.filterProductsByText();
      // this.categoryFilter$.next(null);
      this.filterText$.next(filterValue);
    }
    if (!filterValue) this.filterByCategory(category);

    // filtra los productos que estan en el observable en ese momento.
    // let productTemp: Observable<Product[]>;
    // productTemp = this.productsObs.pipe(
    //   map(products =>
    //     products.filter(product => product.cod.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1)
    //   )
    // );
    // this.productsObs = productTemp;

    // if (!filterValue) this.productsObs = this.productsObsAux;
  }

  showAllProducts() {
    // this.categoryFilter$.next(null);
    this.isAllProducts = true;
    if (this.mobileQuery.matches) {
      this.panelOpenState = false;
      this.panelOpenStatePurses = false;
      this.panelOpenStateToys = false;
    }
    this.reload();
    this.productsObs = this.productsObsAux;
    // console.log(this.panelOpenState);

    // this.categorySelected.nativeElement.selectedOptions.selected[0].value = 'all';
  }

  // pagination without Categories
  nextPage() {
    this.disable_prev = false;
    this.auxCount++;
    // console.log(this.auxCount);
    this.productsObs.subscribe((last) => {
      this.lastProduct = last[last.length - 1];
      console.log(this.lastProduct.name);
      if (this.lastProduct) {
        this.productsObs = this.productService.nextPage(this.activeProductTypeCollection,
          this.pageSize,
          this.lastProduct
        );
        this.productsObs.subscribe((data) => {
          if (data.length < this.pageSize) this.disable_next = true;
          if (data.length == 0) this.isFinalPage = true;
        });
      }
    });
    // this.lastProduct = null;
  }

  previousPage() {
    this.disable_next = false;
    this.auxCount--;
    // console.log(this.auxCount);

    this.productsObs.subscribe((first) => {
      this.firstProduct = first[0];
      // console.log(this.firstProduct.name);
      if (this.firstProduct) {
        this.productsObs = this.productService.prevPage(this.activeProductTypeCollection,
          this.pageSize,
          this.firstProduct
        );
      }
    });
    // this.firstProduct = null;
  }

  ///TODO
  //https://stackblitz.com/edit/infinite-scroll-firestore?file=app%2Fpagination.service.ts

  // nextPage() {
  //   this.productsObs.subscribe(last => {
  //     this.lastProduct = last[last.length - 1];
  //     console.log(this.lastProduct);
  //     if (this.lastProduct) {
  //       this.productsObs = combineLatest(
  //         this.categoryFilter$
  //       ).pipe(
  //         switchMap(([category]) =>
  //           this.afs.collection<Product>('products', ref => {
  //             let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //             if (!category) {
  //               query = query.orderBy('name', 'asc')
  //                 .startAfter(this.lastProduct.name).limit(this.pageSize);
  //               console.log("entra no Next=" + category);

  //             };

  //             if (category) {
  //               query = query.where('category', '==', category).orderBy('name', 'asc')
  //                 .startAfter(this.lastProduct.name).limit(this.pageSize);
  //               console.log("entra Next=" + category);

  //             };
  //             console.log(query);
  //             return query;
  //           }).valueChanges()
  //         )
  //       );
  //     }
  //   });
  // }

  // previousPage() {
  //   // this.productsObs = this.productService.prevPage(this.pageSize, this.firstProduct);

  //   this.productsObs.subscribe(first => {
  //     this.firstProduct = first[0];
  //     console.log(this.firstProduct);
  //     if (this.firstProduct) {

  //       this.productsObs = combineLatest(
  //         this.categoryFilter$
  //       ).pipe(
  //         switchMap(([category]) =>
  //           this.afs.collection<Product>('products', ref => {
  //             let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //             if (!category) {
  //               query = query.orderBy('name', 'asc')
  //                 .endBefore(this.firstProduct.name).limitToLast(this.pageSize);
  //               console.log("entra no Previous=" + category);

  //             };

  //             if (category) {
  //               query = query.where('category', '==', category).orderBy('name', 'asc')
  //                 .endBefore(this.firstProduct.name).limitToLast(this.pageSize);
  //               console.log("entra Previous=" + category);

  //             };
  //             console.log(query);
  //             return query;
  //           }).valueChanges()
  //         )
  //       );
  //     }
  //   });

  // }
  // button(event) {
  //   console.log('on ng model change', event);
  // }
  // compareFunction = (o1: any, o2: any) => {
  //   console.log(o1, o2);
  //   o1 === o2;
  // }
  //   ;
}
