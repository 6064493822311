<div class="upload">
  <div class="upload-image">
    <img [src]="imgURL" height="200" *ngIf="imgURL" />
  </div>

  <div class="form-upload-image">
    <form [formGroup]="uploadForm" (ngSubmit)="saveButton()">
      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Tipo de producto</mat-label>
        <mat-select formControlName="category" (selectionChange)="changeTypeProduct($event)">
          <mat-option *ngFor="let category of categories | async" [value]="category.name">
            {{category.name}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="type.hasError('required')"
      >Por favor elija un tipo de producto</mat-error
    > -->
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Codigo</mat-label>
        <span matPrefix>{{ prefix }}</span>
        <input matInput placeholder="X00DG1" formControlName="cod" />
        <!-- <mat-error *ngIf="type.hasError('required')"
      >Por favor elija un tipo de producto</mat-error
    > -->
      </mat-form-field>

      <mat-form-field appearance="standard" class="example-full-width">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre del producto" formControlName="name" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Precio</mat-label>
        <span matPrefix>S/.</span>
        <input type="number" matInput placeholder="20" formControlName="price" />
        <!-- <mat-error *ngIf="type.hasError('required')"
      >Por favor elija un tipo de producto</mat-error
    > -->
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Descripcion</mat-label>
        <textarea matInput placeholder="Descripcion del producto" formControlName="desc"></textarea>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Video Link</mat-label>
        <textarea matInput placeholder="Link Video del producto" formControlName="videoLink"></textarea>
      </mat-form-field>

      <mat-checkbox class="example-margin" after formControlName="outStock">Agotado</mat-checkbox>
      <mat-checkbox class="example-margin" after formControlName="hotSale">Promocion</mat-checkbox>
      <mat-checkbox class="example-margin" after formControlName="soon">Proximamente</mat-checkbox>

      <button class="btn-save-upload" mat-flat-button color="primary" type="submit" [disabled]="!uploadForm.valid">
        Guardar
      </button>
    </form>
  </div>

  <div *ngIf="percentage | async as pct">
    <progress class="progress" [value]="pct" max="100"> </progress>
    {{ pct | number }}%
  </div>

  <div *ngIf="(snapshot | async) as snap">
    {{ snap.bytesTransferred }} of {{ snap.totalBytes }}
  </div>
</div>
