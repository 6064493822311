<div class="container pt-3">
    <mat-card class="login-card">
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <h2>Log In</h2>

                <mat-form-field class="full-width-input">
                    <input matInput placeholder="Email" formControlName="username" required>
                    <mat-error>
                        Por favor escriba un email valido
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                    <input matInput type="password" placeholder="Password" formControlName="password" required>
                    <mat-error>
                        Este campo es obligatorio
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="loginInvalid">
                    El usuario o password es invalido
                    <br>
                </mat-error>

                <button mat-raised-button color="primary">Login</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>