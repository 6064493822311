import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    // this.user$ = this.afAuth.authState.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       return this.afs.doc(`users/${user.uid}`).valueChanges();
    //     } else {
    //       return of(null);
    //     }
    //   })
    // );
    this.user$ = this.afAuth.authState;
  }

  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    console.log(this.afAuth.authState);
    return this.updateUserData(credential.user);
  }

  async emailAndPasswordSignin(email: string, password: string) {
    // const provider = this.auth.EmailAuthProvider.credential(email,password);
    // const provider = auth.EmailAuthProvider.credential(
    //     email,
    //     password
    // );;

    // const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
    // console.log(this.afAuth.authState);
    // return credential;
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      console.log(this.afAuth.authState);
      return true;
    } catch (error) {
      console.error('Sign in failed', error);
      return false;
    }
  }

  async signOut() {
    // https://dev.to/dobis32/user-authentication-with-angular-angularfire-3eja

    // await this.afAuth.signOut();
    // console.log(this.afAuth.authState);
    // return this.router.navigate(['/']);
    try {
      await this.afAuth.signOut();
      console.log(this.afAuth.authState);
      return this.router.navigate(['/']);
    } catch (error) {
      console.error('Sign out failed', error);
      return false;
    }
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    console.log(this.afAuth.authState);

    return userRef.set(data, { merge: true });
  }
}
