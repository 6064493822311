<footer class="footer footer-black footer-big">
    <div class="container">
        <div class="content">
            <div class="row">
                <div class="col-md-4">
                    <h5>Sobre Nosotros</h5>
                    <p>Mira Que Lindo es una empresa dedicada a la venta de productos y materiales para scrapbooking y
                        manualidades con los mejores precios. Ventas al por mayor y menor. </p>
                </div>
                <div class="col-md-4  ml-auto">
                    <h5>Links Rápidos</h5>
                    <div class="social-feed">
                        <ul>
                            <li routerLinkActive="active"><a routerLink="/home" fragment="top">Inicio</a></li>
                            <li routerLinkActive="active"><a routerLink="/categorias" fragment="top">Categorías</a>
                            </li>
                            <li routerLinkActive="active"><a routerLink="/contacto" fragment="top">Contacto</a></li>
                            <li routerLinkActive="active"><a href="https://www.facebook.com/Miraquelindoperu/?ref=br_rs"
                                    target="_blank">Síguenos en Facebook</a>
                            </li>

                        </ul>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <h5>Instagram Feed</h5>
                    <div class="gallery-feed">
                        <img src="../assets/img/faces/card-profile6-square.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/christian.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/card-profile4-square.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/card-profile1-square.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/marc.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/kendall.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/card-profile5-square.jpg" class="img img-raised rounded" alt="">
                        <img src="../assets/img/faces/card-profile2-square.jpg" class="img img-raised rounded" alt="">
                    </div>
                </div> -->
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="copyright text-center">
            Copyright © 2023 Todos los derechos reservados
            <!-- Copyright © 2020 Todos los derechos reservados | Hecho por Fernando Tello -->
        </div>
    </div>

    <!-- <div class="container text-center">
        <small>Copyright &copy; Mira Que Lindo Todos los derechos reservados | Hecho por Fernando Tello</small>
    </div> -->

</footer>
