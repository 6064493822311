
export class Product {
    cod: string;
    name: string;
    desc: string;
    outStock: boolean;
    creationDate: number;
    downloadURL: string;
    path: string;
    category: string;
    price: number;
    hotSale: boolean;
    soon: boolean;
    type?: string;
    videoLink?: string;
}
