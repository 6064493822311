import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isCollapse = true;
  toggleState() {
    let foo = this.isCollapse;
    this.isCollapse = foo === false ? true : false;
  }
  loggedIn = false;
  loggedUser: any = null;

  constructor() {}
  // constructor(private autorizacionService: AutorizacionService) {
  //   this.autorizacionService.isLogged()
  //     .subscribe((result)=>{
  //       if(result && result.uid) {
  //         this.loggedIn = true
  //         setTimeout(()=>{
  //           this.loggedUser = this.autorizacionService.getUser().currentUser.email
  //         }, 500)
  //       } else {
  //         this.loggedIn = false
  //       }
  //     }, (error)=>{
  //       this.loggedIn = false
  //     })
  // }
  // logout(){
  //   this.autorizacionService.logout()
  // }
  ngOnInit() {}
}
