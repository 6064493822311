import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Product } from '../model/product.model';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productCollection: AngularFirestoreCollection<Product>;
  // private filePath: any;
  // private downloadURL: Observable<string>;

  constructor(private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  public getAllProductsAndMetadata(categoryCollectionName: string): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName); //'products'
    // return this.afs.collection<Product>(`products`).valueChanges();
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    return this.productCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Product;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );

  }
  public getAllProducts(categoryCollectionName: string): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    return this.productCollection.valueChanges();
  }

  public getHomeProducts(categoryCollectionName: string, pageSize: number): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    this.productCollection =
      this.afs.collection<Product>(categoryCollectionName, ref =>
        ref.where("outStock", "in", ["", false]).orderBy('creationDate', 'desc').limit(pageSize));
    return this.productCollection.valueChanges();
  }

  public getPaginatedProducts(categoryCollectionName: string, pageSize: number): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    this.productCollection =
      this.afs.collection<Product>(categoryCollectionName, ref =>
        ref.orderBy('cod', 'asc').limit(pageSize));
    return this.productCollection.valueChanges();
  }

  public nextPage(categoryCollectionName: string, pageSize: number, lastProduct: Product): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    this.productCollection =
      this.afs.collection<Product>(categoryCollectionName, ref =>
        ref.orderBy('cod', 'asc').startAfter(lastProduct.cod).limit(pageSize));
    this.productCollection.valueChanges().subscribe(a => console.log(a));
    return this.productCollection.valueChanges();
  }

  public prevPage(categoryCollectionName: string, pageSize: number, firstProduct: Product): Observable<Product[]> {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    this.productCollection =
      this.afs.collection<Product>(categoryCollectionName, ref =>
        ref.orderBy('cod', 'asc').endBefore(firstProduct.cod).limitToLast(pageSize));
    return this.productCollection.valueChanges();
  }




  // https://stackoverflow.com/questions/48740430/firestore-how-to-get-document-id-after-adding-a-document-to-a-collection
  // https://stackoverflow.com/questions/49682327/how-to-update-a-single-firebase-firestore-document

  // public editProductById(product: Product, newImage?: FileI) {
  public editProductById(categoryCollectionName: string, id: string, product) {
    console.log(product);
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    // if (newImage) {
    //   this.uploadImage(post, newImage);
    // } else {
    // return this.productCollection.doc(product.uid).update(product);
    return this.productCollection.doc(id).update(product);
    // }

  }

  public deleteProductById(categoryCollectionName: string, product) {
    this.productCollection = this.afs.collection<Product>(categoryCollectionName);
    this.storage.storage.refFromURL(product.downloadURL).delete().then(function () {
      console.log("Image successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing image: ", error);
    });
    return this.productCollection.doc(product.id).delete().then(() => {
      console.log("Document successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing document: ", error);

    });

  }


}
