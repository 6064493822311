import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || '/cargar-nuevos-productos';

    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required],
    });

    this.authService.user$.subscribe((res) => {
      if (res) this.router.navigate([this.returnUrl]);
    });
  }

  async onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;
        // this.authService.user$.subscribe(res => console.log(res));
        await this.authService.emailAndPasswordSignin(username, password);
        // this.authService.user$.subscribe(res => console.log(res));
      } catch (err) {
        console.error(err);
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }
}
