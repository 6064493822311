import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UploadComponent } from './admin/upload/upload.component';
import { CategoriesComponent } from './categories/categories/categories.component';
import { AuthGuard } from './shared/services/auth.guard';
import { LoginComponent } from './admin/login/login.component';
import { ContactComponent } from './contact/contact.component';
import { ContainerCategoriesProductsComponent } from './admin/upload/container-categories-products/container-categories-products.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { animation: 'isLeft' } },
  // { path: 'categorias/troqueles, loadChildren: './categories/troqueles.module#RecipesModules'},
  // { path: 'categorias/troqueles', component: TroquelesComponent },
  // { path: 'categorias/sellos', component: SellosComponent },
  { path: 'cargar-nuevos-productos', component: ContainerCategoriesProductsComponent, canActivate: [AuthGuard] },
  { path: 'categorias', component: CategoriesComponent, data: { animation: 'isRight' } },
  { path: 'contacto', component: ContactComponent, data: { animation: 'isLeft' } },
  { path: 'admin', component: LoginComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
