import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Category } from 'src/app/shared/model/category.model';
import { ProductService } from '../shared/services/product.service';
import { Product } from '../shared/model/product.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  categoria: string;
  fragment: string;
  numberOfHomeProducts = 8;

  productsObs: Observable<Product[]>;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    this.productsObs = this.productService.getHomeProducts( 'products',
      this.numberOfHomeProducts
    );
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {
      console.error(e);
    }
  }

  navigateCategory(category: string) {
    this.categoria = category;
    this.router.navigate(['categorias'], {
      queryParams: { category: this.categoria },
      fragment: 'top',
    });
  }
}
