<!-- slider Area Start-->
<!-- <div class="slider-area ">
    <div class="container-image">
        <div class="text-container-image">
            <h1>Categorias</h1>
        </div>
    </div>
</div> -->
<!-- slider Area End-->

<div class="container container-image"
    style="background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/../assets/images/scrapbook-2.jpg')">
    <div class="row">
        <div class="text-container-image col-md-8 ml-auto mr-auto text-center" id="top">
            <h1 class="mat-display-1">Categorías</h1>
            <!-- <h4 class="mat-subheading-1">Venta de productos y materiales para <b>scrapbooking</b> y
                <b>manualidades</b>,
                con los mejores precios.</h4> -->
        </div>
    </div>
</div>
<!-- </div> -->
<!-- slider Area End-->


<div class="main main-raised ">
    <div class="section">

        <div class="container">
            <div class="row">
                <div class="col">
                    <h2>Encuentra todo lo que necesites!</h2>
                </div>
                <!-- <div [hidden]="!isAllProducts"> -->
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <span matPrefix>
                            <mat-icon matSufix aria-hidden="false" aria-label="Search">search</mat-icon>&nbsp;
                        </span>
                        <mat-label>Busca por codigo de producto</mat-label>
                        <input matInput (keyup)="applyFilterText($event,categorias.selectedOptions.selected[0]?.value)"
                            placeholder="B-006" #input style="padding-bottom: 8px;">
                        <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="input.value='';filterByCategory(categorias.selectedOptions.selected[0]?.value)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <!-- </div> -->
            <div class="row">
                <div class="col-md-3">
                    <!--   <div class="card card-refine card-plain card-rose">
                        <div class="card-body">
                            <h4 class="card-title">
                                Refine
                                <button class="btn btn-default btn-fab btn-fab-mini btn-link pull-right" rel="tooltip"
                                    title="" data-original-title="Reset Filter">
                                    <i class="material-icons">cached</i>
                                </button>
                            </h4>
                            <div id="accordion" role="tablist"> -->
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="panelOpenState" (opened)="openState('scrapbook')"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Categorías Scrapbook
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- <mat-selection-list [multiple]="false">
                                <mat-list-option (click)="showAllProducts()"
                                    [class.mat-list-single-selected-option]="isAllProducts">
                                    Todos</mat-list-option>
                                <mat-list-option (click)="filterByCategory(category)" (click)="$event.stopPropagation()"
                                    *ngFor="let category of categories | async">
                                    {{category.name}}</mat-list-option>
                            </mat-selection-list> -->
                            <mat-selection-list #categorias [multiple]="false"
                                (click)="filterByCategory(categorias.selectedOptions.selected[0]?.value);input.value=''">
                                <mat-list-option [value]="'all'"
                                    [disabled]="categorias.selectedOptions.selected[0]?.value == 'all'">Todos
                                </mat-list-option>
                                <mat-list-option *ngFor="let category of categories | async" [value]="category.name"
                                    [disabled]="categorias.selectedOptions.selected[0]?.value == category.name">
                                    {{category.name}}</mat-list-option>
                            </mat-selection-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <!-- </div>
                        </div>
                    </div> -->
                    <mat-divider></mat-divider>

                    <mat-accordion>
                      <mat-expansion-panel [expanded]="panelOpenStatePurses" (opened)="openState('carteras')"
                          (closed)="panelOpenStatePurses = false">
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  Categorías Carteras
                              </mat-panel-title>
                          </mat-expansion-panel-header>

                          <mat-selection-list #categoriasCarteras [multiple]="false"
                              (click)="filterByCategory(categoriasCarteras.selectedOptions.selected[0]?.value);input.value=''">
                              <mat-list-option [value]="'all'" #categoriasCarterasAllOption
                                  [disabled]="categoriasCarteras.selectedOptions.selected[0]?.value == 'all'">Todos
                              </mat-list-option>
                              <mat-list-option *ngFor="let category of categoriesPurses | async" [value]="category.name"
                                  [disabled]="categoriasCarteras.selectedOptions.selected[0]?.value == category.name">
                                  {{category.name}}</mat-list-option>
                          </mat-selection-list>
                      </mat-expansion-panel>
                  </mat-accordion>

                  <mat-divider></mat-divider>

                  <mat-accordion>
                    <mat-expansion-panel [expanded]="panelOpenStateToys" (opened)="openState('juguetes')"
                        (closed)="panelOpenStateToys = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Categorías Juguetes
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list #categoriasJuguetes [multiple]="false"
                            (click)="filterByCategory(categoriasJuguetes.selectedOptions.selected[0]?.value);input.value=''">
                            <mat-list-option [value]="'all'"
                                [disabled]="categoriasJuguetes.selectedOptions.selected[0]?.value == 'all'">Todos
                            </mat-list-option>
                            <mat-list-option *ngFor="let category of categoriesToys | async" [value]="category.name"
                                [disabled]="categoriasJuguetes.selectedOptions.selected[0]?.value == category.name">
                                {{category.name}}</mat-list-option>
                        </mat-selection-list>
                    </mat-expansion-panel>
                </mat-accordion>
                </div>

                <div class="col-md-9">
                    <div class="row">

                        <ng-container *ngIf="productsObs | async as prods">
                            <div class="col-12 col-6 col-md-4" *ngFor="let product of prods">
                                <mat-card class="product-card">
                                    <!-- <mat-card-header> -->
                                    <div class="card-image">
                                        <span *ngIf="product.outStock" class="out-of-stock">
                                            <span class="out-of-stock-tittle">Agotado</span>
                                        </span>
                                        <div *ngIf="product.hotSale" class="ribbon ribbon-top-right">
                                            <span>Promocion</span>
                                        </div>
                                        <!-- <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/t-shirt.png" alt="" /> -->
                                        <div *ngIf="product.soon" class="soon">
                                            <span>Proximamente</span>
                                        </div>
                                        <img src="{{product.downloadURL}}" alt="{{ product.name }}" />
                                    </div>
                                    <!-- </mat-card-header> -->

                                    <mat-card-content class="product-card-content">
                                        <div class="stats">
                                            <div class="stats-container">
                                                <div class="product-title">
                                                    <span class="product_name">{{ product.name }}</span>
                                                    <span class="product_price">S/. {{ product.price }}</span>
                                                </div>
                                                <div class="product-desc">
                                                    <p>{{ product.cod }} - {{ product.desc }} </p>
                                                    <ng-container *ngIf="product.videoLink">
                                                      Video: <a href="{{product.videoLink}}">{{product.videoLink}}</a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-card-content>
                                    <!-- <a class="hero-detail-link"> -->

                                </mat-card>

                                <!-- https://codepen.io/virgilpana/pen/RNYQwB/
                        https://codepen.io/loiff89/pen/jBMNzM -->


                                <!--
                    <mat-card *ngFor="let product of prods">
                        <mat-card-header>

                            <mat-card-title>{{ product.name }}</mat-card-title>
                            <mat-card-subtitle>{{ product.cod }} - {{ product.category }}
                                {{ product.creationDate }}</mat-card-subtitle>
                        </mat-card-header>

                        <img height="100" width="100" alt="{{ product.downloadURL }} product image"
                            src="{{product.downloadURL}}" />

                        </mat-card> -->
                            </div>

                            <!-- cuando no se encuentra nada con ese texto escrito en el filtro -->
                            <ng-container *ngIf="input.value && prods.length==0">
                                <div class="filter-no-items">
                                    No se encontraron registros para el código "{{input.value}}"
                                </div>

                            </ng-container>
                        </ng-container>

                        <div [hidden]="!isFinalPage">
                            <p> Esos fueron todos los productos</p>
                        </div>

                        <div class="col-md-9 ml-auto mr-auto">
                            <div [hidden]="!isAllProducts || input.value">
                                <div class="paginationButtons">
                                    <button mat-raised-button (click)="previousPage()"
                                        [disabled]="auxCount==0 || isFinalPage">
                                        <mat-icon>chevron_left</mat-icon>Anterior
                                    </button>
                                    <button mat-raised-button (click)="nextPage()" [disabled]="disable_next">
                                        Siguiente<mat-icon>chevron_right</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="example-container" [class.example-is-mobile]="mobileQuery.matches"
                    [class.example-is-desktop]="!mobileQuery.matches">

                    <div class="category-tittle" *ngIf="mobileQuery.matches">
                        <button mat-icon-button (click)="snav.toggle()">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <h1>Categorias </h1>
                    </div>

                    <mat-sidenav-container class="example-sidenav-container">
                        <mat-sidenav #snav [opened]="!mobileQuery.matches"
                            [mode]="mobileQuery.matches ? 'over' : 'side'"> -->

                <!-- [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="56" -->
                <!-- <mat-nav-list>
                <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a>
            </mat-nav-list> -->
                <!-- <mat-nav-list>
                <a mat-list-item routerLinkActive="active-link" (click)="showAllProducts()">Todos los productos</a>
                <a mat-list-item routerLinkActive="active-link" (click)="filterByCategory(category)"
                    *ngFor="let category of categories | async">{{category.name}}</a>
            </mat-nav-list> -->




                <!-- <mat-selection-list [multiple]="false">
                                <mat-list-option (click)="showAllProducts()"
                                    [class.mat-list-single-selected-option]="isAllProducts">
                                    Todos los productos </mat-list-option>
                                <mat-list-option (click)="filterByCategory(category)"
                                    *ngFor="let category of categories | async">
                                    {{category.name}}</mat-list-option>
                            </mat-selection-list>
                        </mat-sidenav>


                        <mat-sidenav-content>
                            <div class="sidenav-content">
                                <ng-container *ngIf="productsObs | async as prods">
                                    <main>
                                        <section class="container">

                                            <mat-card class="product-card" *ngFor="let product of prods">
                                                <div class="card-image">
                                                    <span *ngIf="product.outStock" class="out-of-stock">
                                                        <span class="out-of-stock-tittle">Agotado</span>
                                                    </span>
                                                    <img src="{{product.downloadURL}}" alt="{{ product.name }}" />
                                                </div>

                                                <mat-card-content class="product-card-content">
                                                    <div class="stats">
                                                        <div class="stats-container">
                                                            <div class="product-title">
                                                                <span class="product_name">{{ product.name }}</span>
                                                                <span
                                                                    class="product_price">S/.{{ product.price }}</span>
                                                            </div>
                                                            <div class="product-desc">
                                                                <p>{{ product.cod }} - {{ product.desc }} </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </mat-card-content>

                                            </mat-card>

                                            <!-- https://codepen.io/virgilpana/pen/RNYQwB/
                                https://codepen.io/loiff89/pen/jBMNzM -->


                <!--   </section>
                                    </main>
                                </ng-container>

                                <div [hidden]="!isFinalPage">
                                    <p> Esos fueron todos los productos</p>
                                </div>
                                <div [hidden]="!isAllProducts">
                                    <div class="paginationButtons">
                                        <button mat-raised-button (click)="previousPage()"
                                            [disabled]="auxCount==0 || isFinalPage">
                                            <mat-icon>chevron_left</mat-icon>Anterior
                                        </button>
                                        <button mat-raised-button (click)="nextPage()" [disabled]="disable_next">
                                            Siguiente<mat-icon>chevron_right</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
            </div>-->
            </div>
        </div>
    </div>
</div>
