<h1 mat-dialog-title>{{data.product.name}}</h1>
<div mat-dialog-content>
    <form [formGroup]="uploadForm">

        <div class="edit-product-form">
            <mat-form-field appearance="fill">
                <mat-label>Tipo de producto</mat-label>
                <mat-select formControlName="category" (selectionChange)="changeTypeProduct($event)">
                    <mat-option *ngFor="let category of this.data.categories | async" [value]="category.name">
                        {{category.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Codigo</mat-label>
                <span matPrefix>{{ prefix }}</span>
                <input matInput placeholder="A1X00E1" formControlName="cod" />
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre del producto" formControlName="name" />
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Precio</mat-label>
                <span matPrefix>S/.</span>
                <input type="number" matInput placeholder="20" formControlName="price" />
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Descripcion</mat-label>
                <textarea matInput placeholder="Descripcion del producto" formControlName="desc"></textarea>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Video Link</mat-label>
                <textarea matInput placeholder="Link Video del producto" formControlName="videoLink"></textarea>
            </mat-form-field>

            <mat-checkbox class="example-margin" before formControlName="outStock">Agotado?</mat-checkbox>
            <mat-checkbox class="example-margin" before formControlName="hotSale">Promocion?</mat-checkbox>
            <mat-checkbox class="example-margin" before formControlName="soon">Proximamente?</mat-checkbox>

        </div>

    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="updateProduct()" cdkFocusInitial
        [disabled]="!uploadForm.valid">Guardar</button>
</mat-dialog-actions>
