<!-- Dialogos -->
<span [hidden]=true #mensajeEliminandoCategoria> Eliminando categoria _
</span>
<span [hidden]=true #mensajeConfirmarEliminacionCategoria>
    ¿Est&aacute; seguro de eliminar la categoria? </span>

<mat-card class="category-list">
    <div class="centeredButtons">
        <span id="cat">Categorias {{categoryTypeName}}</span>
        <button mat-icon-button color="primary" (click)="openAddCategoryDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor="let category of categories | async">
        <li> {{category.name}}
            <button mat-icon-button color="warn" (click)="deleteCategoryButton(category)">
                <mat-icon>cancel</mat-icon>
            </button>
        </li>
    </div>
</mat-card>
