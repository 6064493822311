<!-- <mat-toolbar class="navbar">
  <span>Atelier de Carmen</span>
  <span class="example-spacer"></span>
  <button
    mat-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    troqueles
  </button>
  <button
    mat-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    sellos
  </button>
  <button
    mat-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    otros
  </button>

  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar> -->

<nav class="navbar sticky-top navbar-expand-lg navbar-light ">
  <a class="navbar-brand" routerLink="/home">
    <img src="../assets/images/logo-sin-fondo.png" width="80px" alt="logo">
  </a>
  <button mat-icon-button class="navbar-toggler collapsed" type="button" (click)="toggleState()">
    <!-- <span class="navbar-toggler-icon"></span> -->
    <mat-icon>menu</mat-icon>
  </button>

  <div class="navbar-collapse" [ngClass]="{ 'collapse': isCollapse }">

    <!-- <ul class="navbar-nav mr-auto">

    </ul> -->

    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a routerLinkActive="is-active" class="nav-link" routerLink="/home">Inicio</a>
      </li>
      <li class="nav-item">
        <a routerLinkActive="is-active" class="nav-link" routerLink="/categorias">Categorias</a>
      </li>
      <li class="nav-item">
        <a routerLinkActive="is-active" class="nav-link" routerLink="/contacto">Contacto</a>
      </li>

      <!-- <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" routerLink="/login">
          <i class="fa fa-lock" aria-hidden="true"></i> Login
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" routerLink="/registro">
          <i class="fa fa-plus-square-o" aria-hidden="true"></i> Sign up</a>
      </li> -->
    </ul>

    <ul class="nav navbar-nav navbar-right">
      <li routerLinkActive="is-active" class="nav-item">
        <a class="nav-link" href="https://www.facebook.com/Miraquelindoperu/?ref=br_rs" target="_blank">
          <img class="icon fb" src="../../assets/icons/icons8-facebook-nuevo.svg" alt="facebook icon">Facebook
        </a>
      </li>
      <!-- <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" routerLink="/categorias">Messenger</a>
      </li> -->
    </ul>

    <ul class="nav navbar-nav navbar-right" *ngIf="loggedIn">
      <!-- <li *ngIf="loggedUser"><a>{{_autorizacionService.email}}</a></li> -->
      <li *ngIf="loggedUser">
        <a class="nav-link disabled"><i class="fa fa-user-o" aria-hidden="true"></i>
          {{loggedUser}}
        </a>
      </li>
      <li>
        <a class="nav-link" (click)="logout()" *ngIf="loggedIn">Logout
          <i class="fa fa-sign-out" aria-hidden="true"></i>
        </a>
      </li>
    </ul>

  </div>
</nav>