import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmDeleteComponent } from 'src/app/shared/dialog-confirm-delete/dialog-confirm-delete.component';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-container-categories-products',
  templateUrl: './container-categories-products.component.html',
  styleUrls: ['./container-categories-products.component.scss']
})
export class ContainerCategoriesProductsComponent implements OnInit {

  constructor(private productService: ProductService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }


}
