<!-- <div class="container container-image">
    <div class="row">
        <div class="text-container-image col-md-8 ml-auto mr-auto text-center">
            <h1 class="mat-display-1 ">Contactanos</h1>
  
        </div>
    </div>
</div> -->
<!-- </div> -->
<!-- slider Area End-->


<!-- https://developers.google.com/maps/documentation/javascript/adding-a-google-map -->

<div class="main main-raised">
    <div class="contact-content">
        <div class="container section">
            <div class="col-xl-4 col-lg-5 col-md-5">
                <div class="section-tittle">
                    <h1>Informacion</h1>
                </div>

            </div>

            <!-- <h2 class="title"></h2> -->
            <div class="row d-flex justify-content-between">

                <div class="col-md-4 justify-content-between">
                    <div class="info info-horizontal">
                        <div class="title">
                            <div class="icon icon-primary">
                                <i class="material-icons">pin_drop</i>
                            </div>
                            <h4>Ubicacion</h4>
                        </div>
                        <div class="description">
                            <!-- <h4 class="info-title">Ubicacion</h4> -->
                            <ul>
                                <li>Encuentranos Ca. Puccini 175 San Borja (alt estación del tren eléctrico San Borja
                                    Sur – Av. Aviación)</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 justify-content-between">
                    <div class="info info-horizontal">
                        <div class="title">
                            <div class="icon icon-primary">
                                <i class="material-icons">phone</i>
                            </div>
                            <h4>Contactanos</h4>
                        </div>
                        <div class="description">
                            <ul>
                                <li>Carmen Sanchez Whastapp <a href="https://wa.me/51951934800">+51 951934800</a></li>
                                <li>Síguenos en Facebook como <a href="https://www.facebook.com/Miraquelindoperu/">Mira
                                        Que Lindo Peru</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 justify-content-between">
                    <div class="info info-horizontal">
                        <div class="title">
                            <div class="icon icon-primary">
                                <i class="material-icons">payment</i>
                            </div>
                            <h4>Medios de pago</h4>
                        </div>
                        <div class="description">
                            <ul>
                                <li>Efectivo</li>
                                <li>Aceptamos tarjetas de crédito y/o débito de todos los bancos</li>
                                <li>Transferencia bancaria</li>
                                <li>Pago Link</li>
                                <li>Yape, Lukita</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>