import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductService } from 'src/app/shared/services/product.service';
import { Product } from 'src/app/shared/model/product.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-edit-product',
  templateUrl: './dialog-edit-product.component.html',
  styleUrls: ['./dialog-edit-product.component.scss']
})
export class DialogEditProductComponent implements OnInit {

  uploadForm: FormGroup;
  product: Product;
  prefix: string;

  constructor(private productService: ProductService,
    public dialogRef: MatDialogRef<DialogEditProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.product = new Product();
    this.prefix = '';
  }

  ngOnInit(): void {
    console.log(this.data.product);
    this.createForm();
  }

  createForm() {
    this.uploadForm = new FormGroup({
      category: new FormControl('', [Validators.required]),
      cod: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      desc: new FormControl('', [Validators.required]),
      outStock: new FormControl(''),
      hotSale: new FormControl(''),
      soon: new FormControl(''),
      type: new FormControl(''),
      videoLink: new FormControl('')
    });

    this.uploadForm.get('cod').setValue((this.data.product.cod).substring(2));
    this.uploadForm.get('name').setValue(this.data.product.name);
    this.uploadForm.get('category').setValue(this.data.product.category);
    this.changeTypeProduct(this.uploadForm.get('category'));
    this.uploadForm.get('price').setValue(this.data.product.price);
    this.uploadForm.get('desc').setValue(this.data.product.desc);
    this.uploadForm.get('outStock').setValue(this.data.product.outStock);
    this.uploadForm.get('hotSale').setValue(this.data.product.hotSale);
    this.uploadForm.get('soon').setValue(this.data.product.soon);
    this.uploadForm.get('type').setValue(this.data.categoryTypeName);
    this.uploadForm.get('type').disable()
    this.uploadForm.get('videoLink').setValue(this.data.product.videoLink);
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  updateProduct(): void {
    this.product.cod = this.prefix + this.uploadForm.get('cod').value;
    this.product.name = this.uploadForm.get('name').value;
    this.product.category = this.uploadForm.get('category').value;
    this.product.price = this.uploadForm.get('price').value;
    this.product.desc = this.uploadForm.get('desc').value;
    this.product.outStock = this.uploadForm.get('outStock').value;
    this.product.hotSale = this.uploadForm.get('hotSale').value ? this.uploadForm.get('hotSale').value : "";
    this.product.soon = this.uploadForm.get('soon').value ? this.uploadForm.get('soon').value : "";
    this.product.type = this.uploadForm.get('type').value ? this.uploadForm.get('type').value : "";
    this.product.videoLink = this.uploadForm.get('videoLink').value ? this.uploadForm.get('videoLink').value : "";

    this.product.creationDate = Date.now();
    this.productService.editProductById(this.data.productCollectionName,this.data.product.id, { ...this.product });
    // this.afs.collection('hackers').doc(this.data.uid).update({ email: this.newEmail })
    this.dialogRef.close();
  }

  changeTypeProduct(type) {
    this.prefix = type.value.toUpperCase().charAt(0) + "-";
    console.log(this.prefix);
  }

}
